

































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { api } from "@/store/modules/audit";
import { api as confApi } from "@/store/modules/configuration";
import { AuditWorkflowStepInfo } from "@/store/modules/audit/types";
import { isString } from "lodash";
import { VForm } from "@/components/types";
import { NamedRef } from "@auditcloud/shared/lib/schemas";

@Component({
  name: "AAuditAdvancedSettings",
})
export default class AAuditAdvancedSettings extends Vue {
  comment = "";
  nextStatus = "";
  loading = false;
  lazyAuditProgramId: null | string = null;

  error: string | null = null;
  get hasError() {
    return this.error !== null;
  }
  set hasError(v: boolean) {
    this.error = v ? " - " : null;
  }

  get auditProgramId() {
    return this.lazyAuditProgramId ?? this.currentAuditProgramId;
  }
  set auditProgramId(id: null | string) {
    if (id !== this.currentAuditProgramId) {
      this.lazyAuditProgramId = id;
    } else {
      this.lazyAuditProgramId = null;
    }
  }

  @Getter(api.getters.getAuditStatus, { namespace: api.namespace })
  currentAuditStatus!: string | null;

  @Getter(api.getters.getAuditProgramId, { namespace: api.namespace })
  currentAuditProgramId!: string | null;

  @Getter(confApi.getters.getAuditProgramList, { namespace: confApi.namespace })
  auditProgramList!: NamedRef[];

  @Getter(api.getters.getWorkflowSteps, { namespace: api.namespace })
  workflowSteps!: AuditWorkflowStepInfo[];

  @Action(api.actions.changeAuditStatusUnrestricted, {
    namespace: api.namespace,
  })
  changeAuditStatusUnrestricted!: (payload: {
    from: string;
    to: string;
    comment: string;
  }) => Promise<string>;

  @Action(api.actions.changeAuditProgram, {
    namespace: api.namespace,
  })
  changeAuditProgram!: (auditProgramId: string) => Promise<void>;

  get rules() {
    return [
      (v: any) =>
        (isString(v) && v.trim().length > 0) ||
        this.$t(
          "components.widgets.sidebar.audit_advanced_settings.rules_required"
        ).toString(),
    ];
  }

  get selectableWorkflowSteps() {
    return this.workflowSteps.filter(
      ws => ws.statusId !== this.currentAuditStatus
    );
  }

  get currentWorkflowStep() {
    const workflowStep = this.workflowSteps.find(
      ws => ws.statusId === this.currentAuditStatus
    );
    return workflowStep ?? null;
  }

  get currentStatusName() {
    return this.$ft(this.currentWorkflowStep?.text ?? "");
  }

  changeStatus() {
    const form = this.$refs["status-change-form"] as VForm;

    const from = this.currentAuditStatus;
    const to = this.nextStatus;

    if (form.validate() && from && to) {
      this.loading = true;
      this.error = null;
      this.changeAuditStatusUnrestricted({
        from,
        to,
        comment: this.comment,
      })
        .then(newStatus => {
          console.log("changeStatus:done", newStatus);
          const newWorkflowStep = this.workflowSteps.find(
            ws => ws.statusId === newStatus
          );
          if (newWorkflowStep) {
            this.$router.push({
              name: newWorkflowStep.route,
            });
          } else {
            console.warn(
              "Unknown new Workflow step ....",
              newStatus,
              this.workflowSteps
            );
          }
        })
        .catch(err => {
          console.error("changeStatus:failed", err);
          this.error = this.$t(
            "components.widgets.sidebar.audit_advanced_settings.error_hint_status_change_failed"
          ).toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  changeProgram() {
    if (this.lazyAuditProgramId) {
      this.loading = true;
      this.error = null;
      this.changeAuditProgram(this.lazyAuditProgramId)
        .then(() => {
          console.log("changeAuditProgram:done");
        })
        .catch(err => {
          console.error("changeAuditProgram:failed", err);
          this.error = this.$t(
            "components.widgets.sidebar.audit_advanced_settings.error_hint_audit_program_change_failed"
          ).toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
