var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"audit-transition"},[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-btn',{staticStyle:{"margin-left":"-6px"},attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-toolbar-title',{staticClass:"pl-2",attrs:{"light":""}},[_vm._v(" "+_vm._s(_vm.$t("components.app.audit_panel_right.audit_advanced_settings"))+" ")])],1),_c('v-card-text',[_c('v-alert',{staticClass:"mb-4",attrs:{"type":"error","dismissible":""},model:{value:(_vm.hasError),callback:function ($$v) {_vm.hasError=$$v},expression:"hasError"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]),(_vm.auditProgramList.length > 0)?_c('v-form',{ref:"status-change-program"},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t( "components.widgets.sidebar.audit_advanced_settings.title_change_program" ))+" ")]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","ma-0":"","pa-0":""}},[_c('v-col',[_c('v-select',{attrs:{"rules":_vm.rules,"items":_vm.auditProgramList,"item-text":function (v) { return _vm.$ft(v.name); },"item-value":"id","label":_vm.$t(
                    'components.widgets.sidebar.audit_advanced_settings.label_audit_program'
                  )},model:{value:(_vm.auditProgramId),callback:function ($$v) {_vm.auditProgramId=$$v},expression:"auditProgramId"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.lazyAuditProgramId === null ||
              _vm.lazyAuditProgramId === _vm.currentAuditProgramId,"loading":_vm.loading,"color":"accent"},on:{"click":_vm.changeProgram}},[_vm._v(" "+_vm._s(_vm.$t( "components.widgets.sidebar.audit_advanced_settings.label_btn_change_program" ))+" ")])],1)],1)],1):_vm._e(),_c('v-form',{ref:"status-change-form"},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t( "components.widgets.sidebar.audit_advanced_settings.title_change_status" ))+" ")]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","ma-0":"","pa-0":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t(
                    'components.widgets.sidebar.audit_advanced_settings.label_current_state'
                  ),"value":_vm.currentStatusName,"readonly":""}}),_c('v-select',{attrs:{"rules":_vm.rules,"items":_vm.selectableWorkflowSteps,"item-text":function (v) { return _vm.$ft(v.text); },"item-value":"statusId","label":_vm.$t(
                    'components.widgets.sidebar.audit_advanced_settings.label_new_state'
                  )},model:{value:(_vm.nextStatus),callback:function ($$v) {_vm.nextStatus=$$v},expression:"nextStatus"}}),_c('v-textarea',{attrs:{"rules":_vm.rules,"label":_vm.$t(
                    'components.widgets.sidebar.audit_advanced_settings.label_comment'
                  )},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"accent"},on:{"click":_vm.changeStatus}},[_vm._v(" "+_vm._s(_vm.$t( "components.widgets.sidebar.audit_advanced_settings.label_btn_change_state" ))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }